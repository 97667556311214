import img1 from "./loc/img1.jpg";
import img2 from "./loc/img2.jpg";
import img3 from "./loc/img3.jpg";
// import img4 from "./loc/img4.jpg";
import img5 from "./loc/img5.jpg";
import img6 from "./loc/img6.jpg";
import img7 from "./loc/img7.jpg";

const images = [
  {
    id: 1,
    title: "We are located in Medan Mall",
    src: img1,
  },
  {
    id: 2,
    title: "You can walk from Medan Mall entrance",
    src: img2,
  },
  {
    id: 3,
    title: "Go up escalator and turn left",
    src: img3,
  },
  // {
  //   id: 4,
  //   title: "And walk to the end to reach us",
  //   src: img4,
  // },
  {
    id: 5,
    title: "A giant F7 is display in front on our shop",
    src: img5,
  },
  {
    id: 6,
    title: "Our shopfront",
    src: img6,
  },
  {
    id: 7,
    title: "Toilet on our left and MR.D.I.Y on our right",
    src: img7,
  },
];

export default images;
