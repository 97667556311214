import React from "react";
import "./homepage.css"
import background from "./img/background.jpg"
import scrollDown from "./img/scroll-down.svg"
import whatsappIcon from "./img/whatsapp.png"

class Heropage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
       companyName: "HI-TECH COMPUTER SALES & SERVICES",
       hpNo: "+60128877012",
       advText1: "Laptop & PC Repair",
       advText2: "Professional Service",
       advText3: "Reasonable Price",
       slogan: '"We are here because of you"',
       scrollDownMsg: "scroll down to see our services"
    }
  }

  chgLang = (event) => {
    const lang = event.target.value
    if (lang === "chn") {
      this.setState({
        companyName: "高科技电脑中心",
        hpNo: "+60128877012",
        advText1: "笔记本及台式机电脑维修",
        advText2: "专业服务",
        advText3: "价格公道",
        slogan: '"因为你，我们在这里"',
        scrollDownMsg: "向下滑动了解我们的服务"
      })
    } else if (lang === "bm") {
      this.setState({
        companyName: "HI-TECH COMPUTER SALES & SERVICES",
        hpNo: "+60128877012",
        advText1: "Pembaikan Komputer Riba & Komputer Meja",
        advText2: "Servis Profesional",
        advText3: "Harga Berpatutan",
        slogan: '"Kerana anda kami di sini"',
        scrollDownMsg: "tatal ke bawah untuk mengenali perkhidmatan kami "
      })
    } else {
      this.setState({
        companyName: "HI-TECH COMPUTER SALES & SERVICES",
        hpNo: "+60128877012",
        advText1: "Laptop & PC Repair",
        advText2: "Professional Service",
        advText3: "Reasonable Price",
        slogan: '"We are here because of you"',
        scrollDownMsg: "scroll down to see our services"
      })
    }
  }

  render() {

    const { companyName, hpNo, advText1, advText2, advText3, slogan, scrollDownMsg } = this.state

    return (
      <div className="hero-page">
        <div className="herobgimgdiv">
          <img className="herobgimg" src={background} alt="Hero Page Background" />
        </div>
        <div className="hero-content">
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <div className="company-name">
              {companyName}<br />
              {hpNo}
            </div>
          </div>
          <div className="language">
              <button onClick={this.chgLang} value="en">EN</button>
              <button onClick={this.chgLang} value="chn">中</button>
              <button onClick={this.chgLang} value="bm">BM</button>
            
          </div>
          <div className="vertical-line"></div>
          <div className="adv-text text1">
            {advText1}
          </div>
          <div className="adv-text text2">
            {advText2}
          </div>
          <div className="adv-text text3">
            {advText3}
          </div>
          <div className="adv-text slogan">
            {slogan}
          </div>
          <div className="hero-footer">
            <p>{scrollDownMsg}</p>
            <img src={scrollDown} alt="Scroll Down" />
          </div>
        </div>
        <div className="float-icon">
          <a href="https://bitly.com/HitechComputer" target="_blank">
            <img src={whatsappIcon} />
            <br />
            <span>Chat With Me</span>
          </a>
        </div>
      </div>
    )
  }
}

export default Heropage