import React from "react"
import Heropage from "./components/Heropage"
import Services from "./components/Services"
import Location from "./components/Location"
import Contact from "./components/Contact"

function App() {
  return (
    <div>
      <Heropage />
      <Services pageTitle="Our Services" />
      {/* <Location /> */}
      <Contact />
    </div>
  );
}

export default App;
