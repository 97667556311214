import React from "react";
import "./contact.css";
import logo from "./img/logo.png";

class Contact extends React.Component {
  render() {
    return (
      <div className="contact-page">
        <div style={{ backgroundColor: "black" }}>
          <h1>&#9830; Contact Us &#9830;</h1>
        </div>
        <div>
          <img className="logo" src={logo} alt="Hi-tech Computer logo" />
        </div>
        <h3 className="company-name-en">Hi-Tech Computer Sales & Services</h3>
        <h3 className="company-name-chn">高科技电脑中心</h3>

        <h3 className="small-title">Contact:</h3>
        <div className="small-content">
          <span>Winston Chung</span>
          <span>0128877012</span>
          <span>(Whatsapp Enable)</span>
          <span>shop: 084315482</span>
        </div>

        {/* <h3 className="small-title">Address:</h3>
        <div className="small-content">
          <span>No. F7, 1st Floor,</span>
          <span>Medan Mall Shopping Centre,</span>
          <span>No.6, Jalan Wong King Huo,</span>
          <span>96000 Sibu, Sarawak.</span>
        </div> */}

        <h3 className="small-title">Hours:</h3>
        <div className="small-content"></div>
        {/* <span>10.00am - 5.30pm</span> */}
        <span>Monday to Saturday</span>
        <span>Sunday Closed</span>

        {/* <p>
          <b>We accept Visa, Master and Sarawak Pay</b>
        </p> */}

        <p>
          <small>
            &copy; 2020 Hi-Tech Computer Sales & Services. All right reserved.
          </small>
        </p>
      </div>
    );
  }
}

export default Contact;
