import React from "react";
import "./location.css";
import images from "./img/locationImg";

function Location() {
  return (
    <div className="location-page">
      <h1>&#9830; Our Location &#9830;</h1>
      {images.map(({ id, title, src }) => {
        return (
          <div className="location-page-block">
            <span>{title}</span>
            <img key={id} src={src} alt={title} />
          </div>
        );
      })}
    </div>
  );
}

export default Location;
