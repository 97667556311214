import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import img4 from "./img4.jpg";
import img5 from "./img5.jpg";
import img6 from "./img6.jpg";
import img7 from "./img7.jpg";
import img8 from "./img8.jpg";
import img9 from "./img9.jpg";

const images = [
  {
    id: 1,
    title: "Battery and keyboard replacement",
    src: img1,
  },
  {
    id: 2,
    title: "LCD screen replacement",
    src: img2,
  },
  {
    id: 3,
    title: "USB port & keyboard replacement",
    src: img3,
  },
  {
    id: 4,
    title: "Hinge replacement",
    src: img4,
  },
  {
    id: 5,
    title: "Deep cleaning for laptop & desktop",
    src: img5,
  },
  {
    id: 6,
    title: "Secondary hard disk for laptop",
    src: img6,
  },
  {
    id: 7,
    title: "Windows format & software installation",
    src: img7,
  },
  {
    id: 8,
    title: "Desktop upgrade",
    src: img8,
  },
  {
    id: 9,
    title: "Various printer services",
    src: img9,
  },
];

export default images;
