import React from "react";
import "./services.css";
import images from "./img/servicesImg";

function Services(props) {
  const { pageTitle } = props
  return (
    <div className="services-page">
      <h1>&#9830; {pageTitle} &#9830;</h1>
      {images.map(({ id, title, src }) => {
        return (
          <div className="services-page-block">
            <span>{title}</span>
            <img key={id} src={src} alt={title} />
          </div>
        );
      })}
    </div>
  );
}

export default Services;
